@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
    html{
        font-family: 'Raleway', sans-serif;
    }
}

@media (max-width: 325px) {
	.padd{
        padding-top: 20rem;
    }
}
@media only screen and (min-width: 330px) and (max-width: 576px) {
	.padd{
        padding-top: 23rem;
    }
}
/* @media only screen and (orientation: landscape) {
    .padd{
        padding-top: 40rem;
    }
    .padd1{
        padding-top: 40rem;
    }
} */
/* @media (min-width: 380px) and (max-width:425px) {
	.padd{
        padding-top: 40rem;
    }
} */